<template>
  <TalpaLoaderWrapper v-if="$apollo.loading" />
  <WelcomeTemplateStyled v-else>
    <div class="mainWrapper">
      <ProfileWrapperStyled>
        <AvatarBadge :size="'5.2rem'" :fontSize="'35px'" :userData="profile" />
        <DetailsStyled v-if="!isMobile">
          <div class="greeting">{{ $t('feed.profile.' + greeting) }}, {{ givenName | capitalize }}</div>
          <div class="info">{{ $t('feed.profile.info') }}</div>
        </DetailsStyled>
      </ProfileWrapperStyled>
      <DateStyled>
        {{ today }}
      </DateStyled>
    </div>
    <div class="mobileDetails" v-if="isMobile">
      <DetailsStyled>
        <div class="greeting">{{ $t('feed.profile.' + greeting) }}, {{ givenName | capitalize }}</div>
        <div class="info">{{ $t('feed.profile.info') }}</div>
      </DetailsStyled>
    </div>
  </WelcomeTemplateStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import get from 'lodash/get'
import { TalpaLoaderWrapper } from '@common/components'
import resizeMixin from '@/mixins/resize'
import { flexStartCenter, flexColumns } from '@styles/mixins'
import AvatarBadge from '@/components/Atomic/Atoms/AvatarBadge.vue'
import PROFILE_QUERY from '#/graphql/profile/show.gql'

const WelcomeTemplateStyled = styled('div')`
  grid-area: welcome;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 0;
  transition: background-color 0.25s ease;
  font-size: 0.8rem;
  border-radius: 10px;
  justify-content: space-between;
  padding: 1rem;
  .mainWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
const ProfileWrapperStyled = styled('div')`
  ${flexStartCenter}
  gap: 1rem;
`
const DetailsStyled = styled('div')`
  ${flexColumns}
  overflow: hidden;
  text-overflow: ellipsis;
  height: fit-content;
  gap: 1.5rem;
  @media (min-width: 768px) {
    white-space: pre;
  }
  .greeting {
    font-size: 20px;
    font-weight: 600;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  .info {
    font-size: 12px;
    color: ${p => p.theme.colors.title};
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
`
const DateStyled = styled('div')`
  ${flexColumns}
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  padding-top: 0.5rem;
  font-weight: 600;
  white-space: pre;
`
export default {
  inject: ['uiSettings'],
  mixins: [resizeMixin],
  components: {
    WelcomeTemplateStyled,
    ProfileWrapperStyled,
    AvatarBadge,
    DetailsStyled,
    DateStyled,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      isMobile: false,
      profile: null,
    }
  },
  computed: {
    greeting() {
      const hours = DateTime.local().hour
      let greeting = hours < 12 ? 'morning' : hours <= 16 && hours >= 12 ? 'afternoon' : 'evening'
      return greeting
    },
    today() {
      const locale = get(this.uiSettings, 'dates').toLowerCase().replace('_', '-')
      const today = DateTime.now().setLocale(locale).toLocaleString(DateTime.DATE_MED)
      return today
    },
    givenName() {
      return get(this.profile, 'firstName', null) ? get(this.profile, 'firstName', null) : get(this.profile, 'name', null)
    },
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.isMobile = size < 768 && size >= 96
      })
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
