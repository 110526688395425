var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : _c('WelcomeTemplateStyled', [_c('div', {
    staticClass: "mainWrapper"
  }, [_c('ProfileWrapperStyled', [_c('AvatarBadge', {
    attrs: {
      "size": '5.2rem',
      "fontSize": '35px',
      "userData": _vm.profile
    }
  }), !_vm.isMobile ? _c('DetailsStyled', [_c('div', {
    staticClass: "greeting"
  }, [_vm._v(_vm._s(_vm.$t('feed.profile.' + _vm.greeting)) + ", " + _vm._s(_vm._f("capitalize")(_vm.givenName)))]), _c('div', {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.$t('feed.profile.info')))])]) : _vm._e()], 1), _c('DateStyled', [_vm._v(" " + _vm._s(_vm.today) + " ")])], 1), _vm.isMobile ? _c('div', {
    staticClass: "mobileDetails"
  }, [_c('DetailsStyled', [_c('div', {
    staticClass: "greeting"
  }, [_vm._v(_vm._s(_vm.$t('feed.profile.' + _vm.greeting)) + ", " + _vm._s(_vm._f("capitalize")(_vm.givenName)))]), _c('div', {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.$t('feed.profile.info')))])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }